import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollToPlugin)

//test

export const scrollWindowTo = (section, offset = 0) => {
  // window.scroll(0, 0, "instant")
  gsap.to(window, {
    scrollTo: { y: section, offsetY: offset, autoKill: false },
    ease: "Power3.easeIn",
  })
}
