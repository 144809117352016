import gsap from "gsap"
import React, { useEffect, useRef, useState } from "react"
import isMobile from "../utils/is_mobile"
import { withDimensions } from "./withDimensions"

const SmoothScroll = props => {
  const viewport = useRef(null)
  const ticker = useRef(null)

  const getDisableScroll = () => {
    let disableScroll = typeof window === `undefined` || isMobile()
    return disableScroll
  }

  const [height, setHeight] = useState(
    (typeof window !== `undefined` && window.innerHeight) || 480
  )
  const [onTop, setOnTop] = useState(true)
  // const [disabled, setDisableScroll] = useState(getDisableScroll())
  const [disabled, setDisableScroll] = useState(false)

  useEffect(() => {
    setDisableScroll(getDisableScroll())
  }, [])

  useEffect(() => {
    var followY = 0
    var ease = 0.3

    if (!disabled) {
      if (ticker.current) gsap.ticker.remove(ticker.current) //!to remove?
      ticker.current = function() {
        followY += (window.pageYOffset - followY) * ease

        var dy = (window.pageYOffset - followY) / 40
        dy = Math.min(Math.max(dy, -3), 3)
        // gsap.set(".viewport", { skewY: dy })
        if (viewport && viewport.current) {
          gsap.set(viewport.current, {
            skewY: dy,
            // transformOrigin: "0",
          })
        }
      }
      gsap.ticker.add(ticker.current)
    } else {
      if (ticker.current) gsap.ticker.remove(ticker.current)
    }

    window.removeEventListener("scroll", onScroll) //!to remove?
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
      if (ticker.current) gsap.ticker.remove(ticker.current)
    }
  }, [disabled])

  useEffect(() => {
    const disableScroll = getDisableScroll()
    setHeight(viewport.current.offsetHeight)
    setDisableScroll(disableScroll)
  }, [props.dimensions])

  useEffect(() => {
    gsap.set(".header", {
      className: "header" + (onTop ? " on-top" : ""),
    })
  }, [onTop])

  const onScroll = () => {
    setOnTop(window.pageYOffset < 1)

    if (!disabled) {
      gsap.to(viewport.current, 1.2, {
        y: -window.pageYOffset,
        ease: "Power4.easeOut",
      })
    }
  }

  //! inspecting
  // probabilmente il problema è che con lo scroll, la pagina si muove al doppio della velocità
  // due onscroll o due ticker?

  return (
    <div
      className={disabled ? "" : "smooth-scroll-container"}
      style={{ height: disabled ? null : height }}
    >
      <div
        className="scroller-viewport"
        ref={viewport}
        // className={disabled ? "" : "smooth-scroll-viewport"}
        style={
          disabled
            ? {}
            : {
                margin: 0,
                width: "100vw",
                overflowX: "hidden",
                position: "fixed",
                willChange: "transform",
                transform: "translate(0px, 0px)",
              }
        }
        children={props.children}
      />
      {/* <div style={{ height: disabled ? 0 : height }} /> */}
    </div>
  )
}

export default withDimensions(SmoothScroll)
