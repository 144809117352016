import Img from "gatsby-image"
import React, { useState } from "react"

import { scrollWindowTo } from "../utils"
import { useIsMobile } from "../utils/use_is_mobile"
import Drawer from "./drawer"
import { LocalizedLink, LocalizedSwitch } from "./localized_link"
import WinesMenu from "./wines_menu"

import menuIcon from "../assets/menu.png"

const Header = ({ path, layout, digitalQrMode, allPrismicWinesection }) => {
  const [open, setOpen] = useState(false)

  const isMobile = useIsMobile()

  return (
    <div className="header">
      <div className="container flex">
        <div style={{ textWrap: "nowrap" }}>
          <div className="language-selector">
            <LocalizedSwitch
              style={{ margin: -10, padding: 10 }}
              children={(path ?? "")?.includes("/en/") ? "🇬🇧" : "🇮🇹"}
            />
          </div>
          {!["/", "/en/"].includes(path) && !digitalQrMode && !isMobile && (
            <LocalizedLink to="/" style={{ marginLeft: 18 }}>
              {layout.header_home_link_text}
            </LocalizedLink>
          )}
          {!isMobile && (
            <LocalizedLink
              to={digitalQrMode ? "/menu/?digitalQr" : "/menu"}
              style={{ marginLeft: 18 }}
            >
              {layout.header_menu_link_text}
            </LocalizedLink>
          )}
          {!isMobile && (
            <WinesMenu
              allPrismicWinesection={allPrismicWinesection}
              layout={layout}
              digitalQrMode={digitalQrMode}
            />
          )}
          {/* {!isMobile &&
            (digitalQrMode ? (
              <LocalizedLink
                to={digitalQrMode ? "/wines/?digitalQr" : "/wines"}
                style={{ marginLeft: 18 }}
              >
                {layout.header_wine_link_text}
              </LocalizedLink>
            ) : (
              <WinesMenu
                allPrismicWinesection={allPrismicWinesection}
                layout={layout}
                digitalQrMode={digitalQrMode}
              />
            ))} */}
        </div>
        <div className="image">
          {/* <img src={logo} alt="Logo" /> */}
          {!digitalQrMode ? (
            <LocalizedLink to="/">
              <Img
                fluid={layout.header_image.localFile.childImageSharp.fluid}
              />
            </LocalizedLink>
          ) : (
            <div onClick={() => scrollWindowTo("body")}>
              <Img
                fluid={layout.header_image.localFile.childImageSharp.fluid}
              />
            </div>
          )}
        </div>
        {!digitalQrMode && !isMobile && (
          <div className="nav">
            <span
              onClick={() => scrollWindowTo("#footer-where", isMobile ? 96 : 0)}
              className="link"
            >
              {layout.header_where_link_text}
            </span>
            <span
              onClick={() => scrollWindowTo("#contact-where")}
              className="link accent"
              children={layout.header_contact_us_link_text}
            />
          </div>
        )}
        {isMobile && (
          <div
            className="nav"
            style={{
              textAlign: "right",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: 16,
            }}
          >
            <img
              src={menuIcon}
              onClick={() => {
                setOpen(true)
              }}
            />
          </div>
        )}
      </div>
      {open && (
        <Drawer
          layout={layout}
          path={path}
          allPrismicWinesection={allPrismicWinesection}
          digitalQrMode={digitalQrMode}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default Header
