import { useEffect, useState } from "react"
import isMobile from "./is_mobile"

export const useIsMobile = () => {
  const [isMobileInternal, setIsMobileInternal] = useState(false)

  useEffect(() => {
    setIsMobileInternal(isMobile())

    return () => {
      setIsMobileInternal(false)
    }
  }, [typeof window !== `undefined` && window.navigator])

  return isMobileInternal
}
