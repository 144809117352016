module.exports = {
  it: {
    path: "it",
    locale: "Italiano",
    default: true,
  },
  en: {
    path: "en",
    locale: "English",
  },
}
