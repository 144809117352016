import React from "react"
import { Link } from "gatsby"
import messengerIcon from "../assets/facebook-messenger.svg"

import Img from "gatsby-image"

const Footer = props => {
  const { layout } = props

  return (
    <div className="footer flex">
      <div className="where" id="footer-where">
        <div className="flex">
          <div>
            <h1>{layout.footer_where_title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: layout.footer_where_text.html,
              }}
            />
            <div style={{ color: "#ffffff", marginTop: 24 }}>
              <Link to="/contributi" style={{ textDecoration: "underline" }}>
                Elenco dei contributi ricevuti anni 2020/2021
              </Link>
            </div>
          </div>
          <div className="image">
            <a href="https://goo.gl/maps/BRgTm3N59mzPdPUu9" target="_blank">
              <div className="image-container">
                <Img
                  fluid={
                    layout.footer_map_desktop.localFile.childImageSharp.fluid
                  }
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="contact-us" id="contact-where">
        <h1>{layout.footer_contact_us_title}</h1>
        <p className="title">
          <a href={"tel:" + layout.footer_contact_us_telephone} target="_blank">
            {layout.footer_contact_us_telephone}
          </a>
        </p>
        <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
          <a href="mailto:vecchiofalconiere@gmail.com" target="_blank">
            Mail: vecchiofalconiere@gmail.com
          </a>
        </p>
        <div
          style={{
            marginTop: 8,
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          <a
            className="to-messenger"
            href="http://m.me/187420957968844"
            target="_blank"
            dangerouslySetInnerHTML={{ __html: layout.footermessenger.html }}
          />
          <img
            src={messengerIcon}
            style={{ marginLeft: 8, verticalAlign: "bottom" }}
          />
        </div>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          {layout.footer_contact_us_social_row.map((item, index) => (
            <a key={index} href={item.social_link.url} target="_blank">
              <Img
                fluid={item.social_icon.localFile.childImageSharp.fluid}
                key={index}
                className="icon"
              />
            </a>
          ))}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: layout.footer_contact_us_text.html,
          }}
          className="should-have-spaces"
        />
      </div>
    </div>
  )
}

export default Footer
