/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import locales from "../locales"
import "../style/main.styl"
import Footer from "./footer"
import Header from "./header"
import SmoothScroll from "./smooth_scroll"

const pageContext = React.createContext({
  locale: "en",
  location: {},
})

export const PageProvider = pageContext.Provider
export const PageConsumer = pageContext.Consumer
export const usePage = () => React.useContext(pageContext)

export const language = {
  code: "en",
}

const Layout = ({
  //location, pageContext,
  children,
  path,
  digitalQrMode,
  location,
}) => {
  // const { location, pageContext } = props
  // language.code = props.pageContext.locale.substring(0, 2)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      prismicCustomMenu(lang: { regex: "/it-??/" }) {
        data {
          enabled
          slug
          nav_title
        }
      }
      prismicLayout(lang: { regex: "/it-??/" }) {
        data {
          header_home_link_text
          header_menu_link_text
          header_wine_link_text
          header_where_link_text
          header_contact_us_link_text
          footermessenger {
            html
          }
          footer_where_title
          footer_where_text {
            html
          }
          footer_contact_us_title
          footer_contact_us_telephone
          footer_contact_us_text {
            html
          }
          footer_contact_us_social_row {
            social_icon {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 48, quality: 100, pngCompressionSpeed: 1) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            social_link {
              url
            }
          }
          header_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footer_map_desktop {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footer_map_mobile {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      prismicLayoutEN: prismicLayout(lang: { regex: "/en-??/" }) {
        data {
          header_home_link_text
          header_menu_link_text
          header_wine_link_text
          header_where_link_text
          header_contact_us_link_text
          footermessenger {
            html
          }
          footer_where_title
          footer_where_text {
            html
          }
          footer_contact_us_title
          footer_contact_us_telephone
          footer_contact_us_text {
            html
          }
          footer_contact_us_social_row {
            social_icon {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 48, quality: 100, pngCompressionSpeed: 1) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            social_link {
              url
            }
          }
          header_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footer_map_desktop {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footer_map_mobile {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      allPrismicWinesection(
        sort: { fields: data___order, order: ASC }
        filter: { lang: { regex: "/it-??/" } }
      ) {
        edges {
          node {
            data {
              slug
              menu_title {
                text
              }
              order
              sections {
                section {
                  document {
                    data {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicWinesectionEN: allPrismicWinesection(
        sort: { fields: data___order, order: ASC }
        filter: { lang: { regex: "/en-??/" } }
      ) {
        edges {
          node {
            data {
              slug
              menu_title {
                text
              }
              order
              sections {
                section {
                  document {
                    data {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let layout = data.prismicLayout.data
  let customMenu = data.prismicCustomMenu.data
  let allPrismicWinesection = data.allPrismicWinesection.edges

  Object.keys(locales).forEach(locale => {
    if ((path ?? "")?.includes("/" + locales[locale].path + "/")) {
      layout = data.prismicLayoutEN.data
      allPrismicWinesection = data.allPrismicWinesectionEN.edges
    }
  })

  return (
    <PageProvider
      value={{ locale: pageContext.locale, location: { ...location } }}
    >
      <div className="vecchiofalconiere">
        <Header
          path={path}
          layout={layout}
          customMenu={customMenu}
          digitalQrMode={digitalQrMode}
          allPrismicWinesection={allPrismicWinesection}
        />
        <SmoothScroll>
          <main>{children}</main>
          {!digitalQrMode && <Footer layout={layout} />}
        </SmoothScroll>
      </div>
    </PageProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
