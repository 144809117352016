const isMobile = () => {
  if (typeof window === `undefined`) return false
  if (typeof navigator === `undefined`) return false

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export default isMobile
